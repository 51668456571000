import styled from 'styled-components';
import { Trans } from 'react-i18next';
import theme from '../../design/theme';
import { Body } from '../Typography';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFC45E;
  border-bottom: solid 1px;
  text-align: center;
  padding: 10px;
`;

const StyledBody = styled(Body)`
  color: ${theme.color.background.darkMode.dark};
  font-size: 14px;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const ExternalListItem = styled.a`
  text-decoration: underline;
  font-weight: bold;
  color: ${theme.color.background.darkMode.dark};
`;

const EOLBanner = () => (
  <Container>
    <StyledBody>
      <Trans>
        This web application is
        {' '}
        <BoldText>no longer supported.</BoldText>
        {' '}
        Please visit
        {' '}
        <ExternalListItem href="https://support.owletcare.com/hc/en-us/articles/32041370335629" target="_blank" rel="noreferrer">Support</ExternalListItem>
        {' '}
        to learn more.
      </Trans>
    </StyledBody>
  </Container>
);

export default EOLBanner;
