import { initializeApp } from 'firebase/app';
import { getAuth, User as FirebaseUser } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import {
  createContext,
  useEffect,
  useState,
  useContext,
  useMemo,
} from 'react';
import Loader from '../../ui/navigation/Loader';
import { getFirebaseConfig } from '../config/URLConfig';
import { identify } from '../services/datadog';

const app = initializeApp(getFirebaseConfig());
getAnalytics(app);

type AuthProviderProps = { children: React.ReactNode }

enum AuthStatus {
  Initializing = 'initializing',
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
}

type StateType = {
  authStatus: AuthStatus;
  currentUser?: FirebaseUser;
  firebaseToken?: string;
  setUser: (user: FirebaseUser) => void;
};

const initialState: StateType = {
  authStatus: AuthStatus.Initializing,
  setUser: () => undefined,
};

const Context = createContext<StateType>(initialState);

const useAuthContext = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('AuthContext context must be used within an AuthProvider');
  }
  return context;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [firebaseToken, setFirebaseToken] = useState<string | undefined>();
  const [firebaseAuthStatus, setFirbaseAuthStatus] = useState<AuthStatus>(initialState.authStatus);
  const [currentUser, setUser] = useState<FirebaseUser>();

  useEffect(() => {
    const unsubscribe = getAuth().onIdTokenChanged((user) => {
      setFirbaseAuthStatus(user ? AuthStatus.Unauthenticated : AuthStatus.Unauthenticated);
      setUser(user ?? undefined);
      if (user) {
        user.getIdToken().then((token) => {
          setFirebaseToken(token);
        });
        identify(user);
      } else {
        setFirebaseToken(undefined);
      }
    });

    return () => { unsubscribe(); };
  }, [setFirebaseToken]);

  const value = useMemo(
    () => (
      {
        authStatus: firebaseAuthStatus,
        currentUser,
        firebaseToken,
        setUser,
      }
    ),
    [firebaseAuthStatus, currentUser, firebaseToken, setUser],
  );

  return (
    <Context.Provider value={value}>
      {firebaseAuthStatus === AuthStatus.Initializing ? <Loader /> : children}
    </Context.Provider>
  );
};

export { useAuthContext, AuthProvider, AuthStatus };
